<template>
  <div class="warp">
    <div v-if="playerShow" id="avideo" class="prism-player"></div>
  </div>
</template>

<script>
import sha1 from 'crypto-js/sha1'

export default {
  name: 'MKStudy',
  data () {
    return {
      videoCourseInfo: {},
      player: null,
      playerShow: true,
      courseData: null, // 课程数据
      recordId: null, // 轮询id
      pollTimeSet: null, // 轮询时间设置
      interval: null, // 定时器
      currentLength: 0, // 当前时长
      dragFlag: 1, // 是否可以拖动  0: 不可随意； 1： 可以随意
      seekFlag: 0, // 拖动状态
      startSeekTime: 0, // 开始拖拽时间
      localLength: 0,
      completeSeekTime: 0, // 本地看过的的时间
      handUp: {}, // 防挂机对象
      fiveTime: '5', // 防挂机倒计时
      countdownInterval: null, // 防挂机倒计时
      handUpTimeInter: null, // 学习时长倒计时
      isfull: false, // 是否全屏
      handUpBoxShow: false,
      randoma: this.generateRandomString(),
      timestamp: new Date().getTime()
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      const type = this.$store.state.deviceType

      window.changeVideo = this.changeVideo
      window.getPosition = this.getPosition
      window.getState = this.getState
      window.appSeekTo = this.appSeekTo
      window.appPause = this.appPause
      window.appPlay = this.appPlay
      window.appResume = this.appResume
      window.dispose = this.dispose
      if (val && type === 1) {
        // ios
        window.webkit.messageHandlers.getData.postMessage('videoCourse')
        window.getVideoCourse = this.getIos
      } else if (val && type === 2) {
        // flutter
        window.Flutter.postMessage('videoCourse')
        window.getVideoCourse = this.getFlutter
        // 安卓
        // this.getAn()
      } else {
        console.log('本地测试')
        // this.studentId = 24640
        this.videoCourseInfo = {
          lessonId: 49515,
          videoId: 1004283132,
          courseId: 42836
        }
        // this.getPollTime();
        this.videoSrc(this.videoCourseInfo)
      }
    }
  },
  created () {},
  computed: {
    // 播放方式  1.连续播放 2.循环播放 3.点击播放) - 1.6.11"
    playMethod () {
      // return this.playFlag;
      return 1
    },
    signaturea () {
      return sha1(process.env.VUE_APP_MOOC_SIGNATUREID + this.randoma + this.timestamp).toString()
    }
  },
  beforeDestroy () {
    // this.onPlayerEnded();
    this.dispose()
    // if (this.handUpTimeInter) {
    //   clearTimeout(this.handUpTimeInter);
    // }
    // if (this.countdownInterval) {
    //   clearInterval(this.countdownInterval);
    // }
  },
  methods: {
    // {
    //   videoInfo:{
    //     lessonId: 49516,
    //     videoId: 1004285115,
    //     courseId: 42895,
    //   },
    //   setData:{}
    // }
    generateRandomString () {
      return (Math.floor(Math.random() * 800000) + 100010).toString()
    },
    getPosition () {
      // 获取视频当前时长
      let data
      this.player.getPosition(function ($emit) {
        data = $emit
      })
      return data
    },
    getState () {
      let data
      this.player.getState(function ($emit) {
        data = $emit
      })
      return data
    },
    appSeekTo (value) {
      this.player.seek(value)
    },
    appPause () {
      this.player.pause()
    },
    appPlay () {
      this.player.play()
    },
    appResume () {
      this.player.resume()
    },
    dispose () {
      // 销毁之前的视频，不销毁的话，它会一直存在
      if (this.player !== null && this.player) {
        this.player.pause()
        this.player.destroy()
        this.player = null
      }
    },
    // 手动切换视频
    changeVideo (data) {
      // alert('changeVideo')
      // console.log(JSON.stringify(data));
      // console.log(data,1,window.Android.getVideoCourse());
      // console.log(JSON.parse(data),2);
      const info = JSON.parse(data)
      this.videoCourseInfo = info.videoInfo
      this.videoSrc(this.videoCourseInfo)
    },
    // 安卓获取
    getAn () {
      const info = JSON.parse(window.Android.getVideoCourse())
      // this.getPollTime();
      this.videoCourseInfo = info.videoInfo
      console.log(info.setData, 'setData')
      // Object.assign(this, data.setData)
      this.videoSrc(this.videoCourseInfo)
    },
    // flutter获取
    getFlutter (data) {
      // alert('flutter:' + JSON.parse(data))
      console.log(data, 'flutterdata')
      // this.getPollTime();
      const info = JSON.parse(data)
      this.videoCourseInfo = info.videoInfo
      console.log(info.setData, 'setData')
      // Object.assign(this, data.setData)
      this.videoSrc(this.videoCourseInfo)
    },
    // ios获取
    getIos (data) {
      // alert('Ios:' + JSON.parse(data))
      console.log(data, 'iosdata')
      // this.getPollTime();
      const info = JSON.parse(data)
      this.videoCourseInfo = info.videoInfo
      console.log(info.setData, 'setData')
      // Object.assign(this, data.setData)
      this.videoSrc(this.videoCourseInfo)
    },
    // 获取轮询时间集合
    getPollTime () {
      this.$request.getFrontPollTime().then((res) => {
        if (res.data.code == 0) {
          this.pollTimeSet = res.data.data
        }
      })
    },
    videoSrc (ele, callback) {
      // this.lastId = this.lessonId;
      // 开始进来调用的视频接口
      // let obj = {
      //   lessonId: this.lessonId,
      //   videoId: ele.mediaUri,
      //   courseId: this.id,
      // };
      this.$request.getPlayAuth(this.videoCourseInfo).then((res) => {
        // this.player.dispose();是销毁之前的视频，不销毁的话，它会一直存在
        this.dispose()
        this.activeInfo = res.data.data
        this.videoCreatMC(res, ele, callback)
        // if (res.data.data.playInfo.sourceType==1) {
        //   // 慕课
        // } else {
        //   this.videoCreatAli(res, ele,callback);
        // }
      })
      // this.getCourseTree();
    },
    toInform (handle, data) {
      // 通知安卓/ios交互
      const type = this.$store.state.deviceType
      if (type === 1) {
        // ios
        if (handle == 'onPlay') {
          window.webkit.messageHandlers.getData.postMessage('onPlay')
        } else if (handle == 'playEnd') {
          window.webkit.messageHandlers.getData.postMessage('playNextHandle')
        } else if (handle == 'fullscreenChange') {
          window.webkit.messageHandlers.getData.postMessage('fullscreenChange')
        } else if (handle == 'onPause') {
          window.webkit.messageHandlers.getData.postMessage('onPause')
        } else if (handle == 'onSeek') {
          window.webkit.messageHandlers.onSeek.postMessage(JSON.stringify(data))
        } else if (handle == 'onTimeupdate') {
          window.webkit.messageHandlers.onTimeupdate.postMessage(JSON.stringify(data))
        } else {

        }
      } else if (type === 2) {
        // flutter
        if (handle == 'onPlay') {
          window.Flutter.postMessage('onPlay')
        } else if (handle == 'playEnd') {
          window.Flutter.postMessage('playNextHandle')
        } else if (handle == 'fullscreenChange') {
          window.Flutter.postMessage('fullscreenChange')
        } else if (handle == 'onPause') {
          window.Flutter.postMessage('onPause')
        } else if (handle == 'onSeek') {
          const tempData = {
            name: 'onSeek',
            value: data
          }
          window.Flutter.postMessage(JSON.stringify(tempData))
        } else if (handle == 'onTimeupdate') {
          const tempData = {
            name: 'onTimeupdate',
            value: data
          }
          window.Flutter.postMessage(JSON.stringify(tempData))
        } else {

        }
        // // 安卓
        // if (handle == 'onPlay') {
        //   window.Android.onPlay()
        // } else if (handle == 'playEnd') {
        //   window.Android.playNextHandle()
        // } else if (handle == 'fullscreenChange') {
        //   window.Android.fullscreenChange()
        // } else if (handle == 'onPause') {
        //   window.Android.videoPause()
        // } else if (handle == 'onSeek') {
        //   window.Android.onSeek(JSON.stringify(data))
        // } else {

        // }
      }
    },
    videoCreatMC (res, ele, callback) {
      const that = this
      // 创建慕课播放器
      this.player = window.EduPlayer.create({
        parent: document.getElementById('avideo'), // 播放器参数
        appId: process.env.VUE_APP_MOOC_APPID,
        nonce: this.randoma,
        timestamp: this.timestamp,
        signature: this.signaturea,
        // autoStart: true,
        autoStart: this.playMethod !== 3,
        // rePlay: this.playMethod == 2 ? true : false, //是否循环播放
        // notAllowFullScreen: true,
        videoData: {
          videoId: this.videoCourseInfo.videoId, // 从后端获取，详见参数说明
          signature: res.data.data.playInfo.playAuth,
          start: res.data.data.currentLength > 0 ? res.data.data.currentLength : 0
        }
      })
      this.player.$on('onPlay', function () {
        that.toInform('onPlay')
      })
      this.player.$on('onPlayEnd', function () {
        // 播放完毕
        that.toInform('playEnd')
      })
      this.player.$on('onFullscreenChange', function () {
        // 全屏事件
        that.isfull = !that.isfull
        that.toInform('fullscreenChange')
        return false
      })
      this.player.$on('onPause', function () {
        // 播放暂停
        that.toInform('onPause')
      })
      this.player.$on('onTimeupdate', function ($emit) {
        // 播放节点更新
        // that.toInform('onTimeupdate', $emit)
        // that.currentLength = $emit.currentTime;
      })
      // 拖动限制
      this.player.$on('onSeek', function ($emit) {
        console.log($emit, '$emit')
        that.toInform('onSeek', $emit)
      })

      // document.getElementById("avideo").appendChild(that.$refs["handUpBox"]);
    },
    postVideo (recordId, currentLength, intervalTime) {
      const obj = {
        courseId: this.videoCourseInfo.courseId,
        currentLength: currentLength,
        length: intervalTime,
        recordId: recordId
      }
      this.$request.postVideo(obj).then((res) => {
        // this.recordId = res.data.data;
        this.completeSeekTime = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.prism-player {
  height: 100vh;
  width: 100vw;
}
.warp {
  background-color:#000;
}
</style>
